<template>
    <div class="login">
        <div>
            <div style="width:100%;display:flex;top:0px;">
                <div class="loginDialog">
                    <div class="loginBtnMain">
                        <div style="display:flex">
                            <div class="loginTitle">Reset Password</div>
                            <a-icon style="margin-right:0;margin-left:auto" type="close" @click="$emit('close')" :style="{ fontSize: '24px', color:'#AAAAAA' }"/>
                        </div>
                        <a-input class="loginInput" placeholder="Email" v-model="forgotEmail" :size="'large'"/>
                        
                        <a-button class="loginBtn" type="primary" :disabled="disabled" :size="'large'" :style="{ fontSize: '20px'}"  @click="handleResetClick">
                            Send Verification
                        </a-button>
                        <div style="background-color:#F0EFEF;height:1px;widht:100%;margin-top:20px"></div>
                        <div class="loginBtnSignUp">Click here? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';

export default {
    data() {
        return {
            disabled: false,
            forgotEmail: '',
        };
    },
    components: {
        
    },
    methods: {
        handleResetClick: async function() {
            if (this.forgotEmail == '')
                return
            var email = this.forgotEmail.toLowerCase().split(" ").join("");
            const res = await api.resetForgot({
                email:email,
                redirecturl:window.location.host
            });
            if (res != undefined){
                this.$message.success('Send success! Please check your email!');
                this.$emit('close');
            }
            else{
                this.$message.error('Send Failed!');
            }
        },
        close(){
            this.$emit('close');
        },
        clickLogin(){
            this.$emit('openLogin');
        },

    },
    mounted(){
        
    },
    created: function () {

    }
}
</script>