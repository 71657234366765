import Vue from 'vue';
import Router from 'vue-router';

const Home = () => import('./views/Home.vue');
const About = () => import('./views/About.vue');
const Resorts = () => import('./views/Resorts.vue');
const Resorts2 = () => import('./views/Resorts2.vue');
const Inspirations = () => import('./views/Inspirations.vue');
const InspirationsDetail = () => import('./views/InspirationsDetail.vue');
const BestOffers = () => import('./views/BestOffers.vue');
const Contact = () => import('./views/Contact.vue');
const HotelDetail = () => import('./views/HotelDetail.vue');
const BookingSummary = () => import('./views/BookingSummary.vue');

const VerifyEmail = () => import('./views/VerifyEmail.vue');
const ResetPassword = () => import('./views/ResetPassword.vue');

const PrivacyPolicy = () => import('./views/PrivacyPolicy.vue');

const TermsCondition = () => import('./views/TermsCondition.vue');
// import Login from './views/Login.vue';
// import Signup from './views/Signup.vue';
// import SignupAgent from './views/SignupAgent.vue';
Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/home',
      redirect: '/'
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/resorts',
      name: 'resorts',
      component: Resorts
    },
    {
      path: '/resorts2',
      name: 'resorts2',
      component: Resorts2
    },
    {
      path: '/inspiration',
      name: 'inspiration',
      component: Inspirations
    },
    {
      path: '/inspirationsDetail',
      name: 'inspirationsDetail',
      component: InspirationsDetail
    },
    {
      path: '/bestoffers',
      name: 'bestoffers',
      component: BestOffers
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/resort/:hotelname',
      name: 'hotelDetail',
      component: HotelDetail
    },
    {
      path: '/bookingSummary',
      name: 'bookingSummary',
      component: BookingSummary
    },

    
    {
      path: '/verify-email',
      name: 'verifyEmail',
      component: VerifyEmail
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: PrivacyPolicy
    },
    {
      path: '/termscondition',
      name: 'termscondition',
      component: TermsCondition
    },
   
  ]
});