<template>
  <div id="app" style="width:calc(100% + 10px);" v-bind:style="[$mq === 'sm' ? {'width': '100%'} : {}]">
    <!-- <div class="ui inverted segment navbar">
      <div class="ui center aligned container">
        <div class="ui large secondary inverted pointing menu compact">
          <router-link to="/tasks" exact class="item">
           <i class="tasks icon"></i> Tasks
          </router-link>
          <router-link to="/tasks/new" class="item">
            <i class="plus circle icon"></i> New
          </router-link>
      isWhite
        </div>
      </div>
    </div> -->

    <MainHeader :navbarFixed="false" :isWhite="isWhite" style="width:100%" v-bind:style="[isWhite? {'position': 'absolute'} : {}]"></MainHeader>
    <!-- <div class="ui text container">
      <div class="ui one column grid">
        <div class="column">
          <router-view />
        </div>
      </div>
    </div> -->
    <a-layout-content style="min-height: 80vh;">
      <router-view />
    </a-layout-content>
    
    <MainFooter></MainFooter>
  </div>
</template>

<script>

import MainHeader from './components/Headers/MainHeader' ;
import MainFooter from './components/Footers/MainFooter' ;

export default {
  name: 'app',
  data() {
    return {
      isWhite:true
    }
  },

  components: {
    MainHeader,
    MainFooter,
	},
  created(){
    if (window.performance) {
      console.info("window.performance works fine on this browser");
    }
    console.info(performance.navigation.type);
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      console.info( "This page is reloaded" );
      
			this.$store.commit('setBookListInfo', {
                userid: '',
                data:[],
                searchStartDate:'',
                searchEndDate:''
            });
    } else {
      console.info( "This page is not reloaded");
    }
    
    var currentUrl = window.location.pathname;
    const words = currentUrl.split('/');
    
    if (words[1] == '' || words[1] == "hotelDetail" || words[1] == "resorts") {
      this.isWhite = true;
    }
    else {
      this.isWhite = false;
    }

    
  },
  updated(){
    var currentUrl = window.location.pathname;
    const words = currentUrl.split('/');
    
    if (words[1] == '' || words[1] == "hotelDetail" || words[1] == "resorts") {
      this.isWhite = true;
    }
    else {
      this.isWhite = false;
    }
  }
  
};
</script>

<style>
#app > div.navbar {
  margin-bottom: 1.5em;
}
.myFlash {
  width: 250px;
  margin: 10px;
  position: absolute;
  top: 50;
  right: 0;
}
input {
  width: 300px;
}
div.label {
  width: 120px;
}
div.input {
  margin-bottom: 10px;
}
button.ui.button {
  margin-top: 15px;
  display: block;
}


@font-face {
  font-family: "Montserrat light";
  src: local("Montserrat light"),
   url(./assets/fonts/montserratl.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat medium"),
   url(./assets/fonts/montserrat.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  src: local("Montserrat ExtraBold"),
   url(./assets/fonts/montserratb.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway light";
  src: local("raleway light"),
   url(./assets/fonts/ralewayl.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("raleway medium"),
   url(./assets/fonts/raleway.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway Bold";
  src: local("Raleway Bold"),
   url(./assets/fonts/ralewayb.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
   url(./assets/fonts/OpenSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans light";
  src: local("OpenSans light"),
   url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Satoshi light";
  src: local("Satoshi-Light"),
   url(./assets/fonts/Satoshi-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi-Regular"),
   url(./assets/fonts/Satoshi-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Satoshi bold";
  src: local("Satoshi-Bold"),
   url(./assets/fonts/Satoshi-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "General Sans light";
  src: local("GeneralSans-Light"),
   url(./assets/fonts/GeneralSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "General Sans";
  src: local("GeneralSans-Regular"),
   url(./assets/fonts/GeneralSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "General Sans bold";
  src: local("GeneralSans-Bold"),
   url(./assets/fonts/GeneralSans-Bold.ttf) format("truetype");
}


@font-face {
  font-family: "HelveticaNeueBold";
  src: local("HelveticaNeueBold"),
   url(./assets/fonts/HelveticaNeueBold.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
   url(./assets/fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: local("HelveticaNeue-Medium"),
   url(./assets/fonts/HelveticaNeue-Medium.otf) format("truetype");
}

@font-face {
  font-family: "farnhamDisplay-LightItalic";
  src: local("farnhamDisplay-LightItalic"),
   url(./assets/fonts/farnhamDisplay-LightItalic.woff2) format("truetype");
}
@font-face {
  font-family: "farnhamdisplay-light-webfont";
  src: local("farnhamdisplay-light-webfont"),
   url(./assets/fonts/farnhamdisplay-light-webfont.woff2) format("truetype");
}

@font-face {
  font-family: "worksans-medium";
  src: local("worksans-medium"),
   url(./assets/fonts/worksans-medium.woff2) format("truetype");
}
@font-face {
  font-family: "worksans-regular";
  src: local("worksans-regular"),
   url(./assets/fonts/worksans-regular.woff2) format("truetype");
}
@font-face {
  font-family: "DM Sans";
  src: local("DM Sans"),
   url(./assets/fonts/DMSans-Regular-BF64376d323f095.ttf) format("truetype");
}
</style>