import axios from 'axios';
import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';
import 'vue-flash-message/dist/vue-flash-message.min.css';

Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
    pauseOnInteract: true
  }
});

const vm = new Vue();

var baseURL = 'https://api.vuecollection.com/';
if (process.env.NODE_ENV == "development")
  baseURL = 'http://localhost:3000/';//http://localhost:3000/

const handleError = fn => (...params) =>
  fn(...params).catch(error => {
    // if (error.response != undefined)
      vm.flash(`${error.response.status}: ${error.response.statusText}`, 'error');
  });

export const api = {
  gettask: handleError(async id => {
    const res = await axios.get(baseURL + 'tasks/' + id);
    return res.data;
  }),
  gettasks: handleError(async () => {
    const res = await axios.get(baseURL + 'tasks/');
    return res.data;
  }),
  deletetask: handleError(async id => {
    const res = await axios.delete(baseURL + 'tasks/' + id);
    return res.data;
  }),
  createtask: handleError(async payload => {
    const res = await axios.post(baseURL + 'tasks/', payload);
    return res.data;
  }),
  updatetask: handleError(async payload => {
    const res = await axios.put(baseURL + 'tasks/' + payload._id, payload);
    return res.data;
  }),

  
  signup: handleError(async payload => {
    payload['email'] = payload['email'].toLowerCase();
    const res = await axios.post(baseURL + 'users/', payload);
    return res.data;
  }),
  login: handleError(async payload => {
    payload['email'] = payload['email'].toLowerCase();
    const res = await axios.post(baseURL + 'auth/', payload);
    return res.data;
  }),
  
  resendVerify: handleError(async payload => {
    payload['email'] = payload['email'].toLowerCase();
    const res = await axios.post(baseURL + 'usersResend/', payload);
    return res.data;
  }),
  
  verifyEmail: handleError(async (token, email) => {
    const res = await axios.get(baseURL + 'verify-email?token=' + token + '&email=' + email);
    return res.data;
  }),


  getInstagramInfo: handleError(async token => {
    const res = await axios.get('https://graph.instagram.com/me/media?fields=caption,comments_count,like_count,media_type,permalink,media_url&access_token=' + token);
    return res.data; 
  }),

  resetForgot: handleError(async payload => {
    const res = await axios.post(baseURL + 'userreset/', payload);
    return res.data;
  }),

  resetPassword: handleError(async payload => {
    const res = await axios.post(baseURL + 'reset-password/', payload);
    return res.data;
  }),

  
  getLogoInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'headerinfo/', payload);
    return res.data;
  }),

  getBackgroundImages: handleError(async payload => {
    const res = await axios.get(baseURL + 'homebackgroundimages/', payload);
    return res.data;
  }),
  
  getSocialInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'socialIcon/', payload);
    return res.data;
  }),
  
  subscribe: handleError(async payload => {
    const res = await axios.post(baseURL + 'subscribe/', payload);
    return res.data;
  }),
  
  getAboutInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'aboutinfo/', payload);
    return res.data;
  }),
  
  getBestHotelInfoAll: handleError(async payload => {
    const res = await axios.get(baseURL + 'bestHotelInfo/', payload);
    return res.data;
  }),
  ///////////    Same with admin
  getHotels: handleError(async payload => {
    const res = await axios.get(baseURL + 'hotels/', payload);
    return res.data;
  }),
  
  getHotelsTotal: handleError(async payload => {
    const res = await axios.get(baseURL + 'hotelstotal/', payload);
    return res.data;
  }),
  
  getHotel: handleError(async name => {
    const res = await axios.get(baseURL + 'hotels/' + name);
    return res.data;
  }),

  getHotelById: handleError(async id => {
    const res = await axios.get(baseURL + 'admin-hotels/' + id);
    return res.data;
  }),
  
  getHotelRooms: handleError(async id => {
    const res = await axios.get(baseURL + 'hotelrooms/' + id);
    return res.data;
  }),
  
  getRoom: handleError(async id => {
    const res = await axios.get(baseURL + 'rooms/' + id);
    return res.data;
  }),
  getRoomsTotal: handleError(async payload => {
    const res = await axios.get(baseURL + 'roomstotal/', payload);
    return res.data;
  }),
  
  searchResort: handleError(async payload => {
    const res = await axios.post(baseURL + 'bookingsearch/', payload);
    return res.data;
  }),
  getResortBooking: handleError(async payload => {
    const res = await axios.post(baseURL + 'bookingget/', payload);
    return res.data;
  }),

  searchItwxHotel: handleError(async payload => {
    const res = await axios.post(baseURL + 'searchitwxhotel/', payload);
    return res.data;
  }),
  
  getCurrencyInfos: handleError(async token => {
    console.log(token);
    const res = await fetch('https://v6.exchangerate-api.com/v6/a812264b9f56980203f83d89/latest/USD');
    
    const data = await res.json();
    return data.conversion_rates; 
  }),
  
  createPrice: handleError(async (amount) => {
    const res = await fetch(baseURL + "stripe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ amount: amount })
    });
    return res;
  }),

  
  bookingRequest: handleError(async payload => {
    const res = await axios.post(baseURL + 'bookingRequest/', payload);
    return res.data;
  }),
  bookingRequestUpdate: handleError(async (payload, id) => {
    const res = await axios.put(baseURL + 'bookingRequest/' + id, payload);
    return res.data;
  }),
  bookingRequestUpdatePay: handleError(async (payload, id) => {
    const res = await axios.put(baseURL + 'bookingRequestPay/' + id, payload);
    return res;
  }),
  
  getBookingRequestInfo: handleError(async id => {
    const res = await axios.get(baseURL + 'bookingRequest/' + id);
    return res.data;
  }),

  createBookingPdf: handleError(async id => {
    const res = await axios.get(baseURL + 'createBookingPdf/' + id, { responseType: 'blob' });
    var blob = new Blob([res.data], {type: res.headers['content-type']})
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Quotation';// name the downloaded file
    link.click()

    return res;
  }),

  sendHoldRoom: handleError(async payload => {
    const res = await axios.post(baseURL + 'bookingHoldRoom/', payload);
    return res.data;
  }),
  removeBookingRequest: handleError(async id => {
    const res = await axios.delete(baseURL + 'bookingRequest/' + id);
    return res.data;
  }),


  /// Customize Pages
  
  getAboutPageInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'aboutpageinfo/', payload);
    return res.data;
  }),
  getContactPageInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'contactpageinfo/', payload);
    return res.data;
  }),
  sendContactInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'sendcontactinfo/', payload);
    return res.data;
  }),

  //  Inspiration
  
  getInspirations: handleError(async payload => {
    const res = await axios.get(baseURL + 'inspiration/', payload);
    return res.data;
  }),
  getInspirationById: handleError(async id => {
    const res = await axios.get(baseURL + 'inspiration/' + id);
    return res.data;
  }),
  
  /////  Inspiration Tag
  setInspirationTagInfo: handleError(async payload => {
    const res = await axios.post(baseURL + 'inspirationtags/', payload);
    return res.data;
  }),
  getInspirationTagInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'inspirationtags/', payload);
    return res.data;
  }),

// stripe
  callCustomerApi:async function(_email){
    const _resCustomer = await this.getCustomer(_email);
    if (_resCustomer == undefined || _resCustomer.length <= 0 || _resCustomer[0].customerId == undefined || _resCustomer[0].customerId == ''){
      var _infoStripe = {
        email:_email,
        description: 'Customer payment'
      }
      const _resCustomerCreateStripe = await this.createCustomerStripe(_infoStripe);
      var _info = {
        email:_email,
        customerId: _resCustomerCreateStripe['id']
      }
      const _resCustomerCreate = await this.createCustomer(_info);
      return _resCustomerCreate.data.customerId;
    }
    else{
      return _resCustomer[0].customerId
    }
  },

  createCustomer: handleError(async payload => {
    const res = await axios.post(baseURL + 'stripeinfo/', payload);
    return res.data;
  }),
  
  createCustomerStripe: handleError(async payload => {
    const res = await axios.post(baseURL + 'create-customer/', payload);
    return res.data;
  }),
  getCustomer: handleError(async _email => {
    const res = await axios.get(baseURL + 'stripeinfo/' + _email);
    return res.data;
  }),

  createCard: handleError(async payload => {
    const res = await axios.post(baseURL + 'create-card-token/', payload);
    return res.data;
  }),

  createPaymentIntent: handleError(async payload => {
    const res = await axios.post(baseURL + 'create-payment-intent/', payload);
    return res.data;
  }),
  confirmPayment: handleError(async payload => {
    const res = await axios.post(baseURL + 'confirm-payment/', payload);
    return res.data;
  }),


  
  sendPayDirect: handleError(async id => {
    const res = await axios.post(baseURL + 'sendpaydirect/' + id);
    return res.data;
  }),

  
  getResortStyleInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'resortStyleSetting/', payload);
    return res.data;
  }),
  getResortTypeTravelInfo: handleError(async payload => {
    const res = await axios.get(baseURL + 'resortTypeTravelSetting/', payload);
    return res.data;
  }),

};