<template>
	<component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top" v-bind:style="[isWhite? {} : {'box-shadow': '4px 4px 29px rgba(0, 0, 0, 0.09)', 'background':'white'}]">
		<a-layout-header class="headerMainDiv">
            
			<div class="headerMainFirstDiv">
				<img class="logoImage" onclick="location.href='/';" :src="isWhite ? '/images/logo_2.png' : '/images/logo_2_brown.png'"/>
				<!-- <div class="logoTitle" onclick="location.href='/';" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">{{logoName}}</div> -->
			</div>
            <div class="AppBarBtns" v-if="$mq === 'lg'">
				<!-- href="/about/" -->
				<div class="AppBarBtnsSub">
					<a-button type="primary" @click="gotoAbout" target="" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						ABOUT
					</a-button>
					<a-button type="primary" @click="gotoResorts" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						RESORTS
					</a-button>
					<a-button type="primary" @click="gotoInspirations" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						INSPIRATION
					</a-button>
					<a-button type="primary" @click="gotoBestOffers" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						BEST OFFERS
					</a-button>
					<a-button type="primary" @click="gotoContact" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						CONTACT
					</a-button>
				</div>
				<!-- <a-select class="headerDorpdown" v-model="selectedCurrrency" style="width: 130px" @change="handleProvinceChange" :size="'large'">
					<a-select-option v-for="province in provinceData" :key="province.name" :value="province.name">
						<div style="display:flex">
							<flag :iso="province.code" />
							<span style="margin-left:15px;margin-top:auto;margin-bottom:auto;">{{ province.name }}</span>
						</div>
					</a-select-option>
				</a-select> -->
                <div class="LoginBtns">
					<a-button v-if="!isLogin" type="primary" @click="showLogin(true)" v-bind:style="[isWhite ? {} : {'color': '#e1c8b0'}]">
						Sign In
					</a-button>
					<!-- <div v-if="!isLogin" class="headerBar" v-bind:style="[isWhite ? {} : {'background': '#e1c8b0'}]"></div> -->
					<a-button v-if="!isLogin" type="primary" @click="showSignup(true)" v-bind:style="[isWhite? {} : {'color': '#e1c8b0'}]">
						Join
					</a-button>
					<!-- <a-popconfirm title="Are you sure to Logout?" ok-text="Yes" cancel-text="No" @confirm="logout">
						<a-button v-if="isLogin" type="primary">
							{{userInfo.name}}
						</a-button>
					</a-popconfirm> -->
					
					<a-popover v-model="visibleUserPanel" :title="`${userInfo.email != undefined ? userInfo.email : userInfo.name}`" trigger="click" placement="bottomRight">
						<div slot="content" style="text-align: center;">
							<a @click="logout">Logout</a>
						</div>
						<div v-if="isLogin" class="mainUserName">
							<div v-if="isWhite" style="color:#ffffff">
								{{userInfo.name}}
							</div>
							<div v-else style="color:#e1c8b0">
								{{userInfo.name}}
							</div>
						</div>
					</a-popover>
                </div>
            </div>
			<div class="MenuIconDiv" v-else>
                <a-button type="primary" target="" @click="showMenu">
                    <a-icon class="MenuIcon" type="menu" v-bind:style="[isWhite ? {} : {'color': '#e1c8b0'}]"/>
                </a-button>
				<a-drawer
					:placement="placementMenu"
					:visible="visibleMenu"
					:closable="false"
					@close="onCloseMenu"
					class="menuDrawerMain"
					>
					<div class="MenuCloseBtnDiv">
						<div class="MenuTitle">Menu</div>
						<a-icon class="MenuCloseBtn" type="close" @click="onCloseMenu" />
					</div>
					<div class="MenuCell" @click="gotoAbout">ABOUT</div>
					<div class="MenuCell" @click="gotoResorts">RESORTS</div>
					<div class="MenuCell" @click="gotoInspirations">INSPIRATION</div>
					<div class="MenuCell" @click="gotoBestOffers">BEST OFFERS</div>
					<div class="MenuCell" @click="gotoContact">CONTACT</div>
					<a-divider style="color:#EED5BF"/>
					<div style="display:flex">
						<!-- <a-select class="headerDorpdown" v-model="selectedCurrrency" style="width: 130px;" @change="handleProvinceChange" :size="'large'">
							<a-select-option v-for="province in provinceData" :key="province.name" :value="province.name">
								<div style="display:flex">
									<flag :iso="province.code" />
									<span style="margin-left:15px;margin-top:auto;margin-bottom:auto;">{{ province.name }}</span>
								</div>
							</a-select-option>
						</a-select> -->

						<a-popover v-model="visibleUserPanel" :title="`${userInfo.email != undefined ? userInfo.email : userInfo.name}`" trigger="click" placement="bottomRight">
							<div slot="content" class="MenuLoginBtnDiv">
								<a @click="logout">Logout</a>
							</div>
							<div v-if="isLogin" class="MenuLoginedDiv" >
								{{userInfo.name}}
							</div>
						</a-popover>

					</div>

					<div style="display:flex" v-if="!isLogin">
						<div class="MenuCell" @click="showLogin(true)">Login</div>
						<a-divider style="color:#EED5BF;margin-top:38px;" type="vertical" />
						<div class="MenuCell" @click="showSignup(true)">Sign up</div>
					</div>
					<!-- <div style="display:flex" v-if="isLogin">
						<a-popconfirm title="Are you sure to Logout?" ok-text="Yes" cancel-text="No" @confirm="logout">
							<div class="MenuCell">{{userInfo.name}}</div>
						</a-popconfirm>
					</div> -->
					
				</a-drawer>
			</div>
			<a-modal
				v-model="visibleLogin"
				title="Login"
				:footer="null"
			>
				<LoginView v-if="visibleLogin" @success="showLoginSuccess()" @close="showLogin(false)" @openSignup="closeShowSignup()" @openForgot="closeShowForgot()"/>
			</a-modal>
			<a-modal
				v-model="visibleSignup"
				title="Sign-up"
				:footer="null"
			>
				<SignupView v-if="visibleSignup" @success="showSuccess" @close="showSignup(false)" @openLogin="closeShowLogin()" @openSignupAgent="closeShowSignAgent()" @openForgot="closeShowForgot()"/>
			</a-modal>
			<a-modal
				v-model="visibleAgentSignup"
				title="Sign-up"
				:footer="null"
			>
				<SignupAgentView v-if="visibleAgentSignup" @success="showSuccessAgent" @close="showAgentSignup(false)" @openLogin="closeShowLogin()"  @openForgot="closeShowForgot()"/>
			</a-modal>
			<a-modal
				v-model="visibleForgot"
				title="Reset Password"
				:footer="null"
			>
				<ForgotView v-if="visibleForgot" @close="showForgot(false)" @openLogin="closeShowLogin()" @openSignup="closeShowSignup()"/>
			</a-modal>

            <a-modal class="SignUpDiv"
                v-model="visibleSignUpSuccess"
                title=""
                :footer="null"
				:width="'auto'"
				:dialog-style="{'align-items': 'center', 'max-width': '760px', 'top': '100px', 'width': '100%', 'height': '100%'}"
            >
                <div class="SignUpMainDiv">
					<div class="SignUpLogoTitleDiv" style="padding: 15px;">
						<!-- <img class="logoImage2" :src="'/images/logo1.png'"/> -->
						<div class="logoTitle2" style="text-align: center;">{{logoName}}</div>
					</div>
					<div class="SignUpTextDiv">
						<div class="SignUpUserTitle">On your way to Paradise!</div>
						<img class="SignUpUserImg" src="../../assets/images/icon_tree.png" alt="" width="20px" height="30px">
					</div>
					<div class="SignUpUserDetail1">
						Please check your inbox to verify your email address.
					</div>
					<!-- <div class="SignUpUserDetail2" style="margin-top: 3px;margin-bottom: 30px;padding-left: 20px;padding-right: 20px;">
						And enjoy best rates and offers from our unique collection of Collection resorts.
					</div> -->
        
                </div>
            </a-modal>
            <a-modal class="SignUpDiv"
                v-model="visibleSignUpSuccessAgent"
                title=""
                :footer="null"
				:width="'auto'"
				:dialog-style="{'align-items': 'center', 'max-width': '760px', 'top': '100px', 'width': '100%', 'height': '100%'}"
            >
                <div class="SignUpAgentMainDiv">
					<div class="SignUpAgentLogoTitleDiv">
						<!-- <img class="logoImage2" :src="'/images/logo1.png'"/> -->
						<div class="logoTitle2" style="text-align: center;">{{logoName}}</div>
					</div>
					<div style="display:flex;margin-top: 20px;">
						<div class="SignUpUserTitleAgent" style="margin-left: auto;margin-right: auto;margin-top: auto;margin-bottom: auto;">Thank you for registering.</div>
					</div>
					<div class="SignUpUserDetail2">
						We will review your information and get back to you within 24 hours.
						Once you receive a confirmation email that your account has been approved,
						you will be able to access our commissionable rates.
					</div>
        
                </div>
            </a-modal>
			<!-- 
				:mask="false"
				:maskClosable="false" -->
			<a-modal class="SignUpDiv"
                v-model="visibleLoginSuccess"
				title=""
				:footer="null"
				:width="'auto'"
				:dialog-style="{'align-items': 'center', 'max-width': '170px', 'top': '10px', 'width': '100%', 'height': '100%'}"
			>
				
                <div class="LoginSuccessMainDiv">
					<div class="LoginSuccessLogoDiv">
						<!-- <img class="logoImage2" style="width:35px;height:35px; margin-right: 5px;" :src="'/images/logo_black.png'"/> -->
						<div class="logoTitle2" style="font-size: 16px; text-align: center;">{{logoName}}</div>
					</div>
					<div style="display:flex;margin-top: 5px;">
						<div class="LoginUserTitleAgent">Login success!</div>
					</div>
        
                </div>
			</a-modal>
		</a-layout-header>

	</component>

</template>

<script>
import LoginView from '../LoginDialog.vue'
import SignupView from '../SignupDialog.vue'
import SignupAgentView from '../SignupAgentDialog.vue'
import ForgotView from '../ForgotDialog.vue'
import { api } from '../../helpers/Helpers';
import { common } from '../../helpers/Common';
import { bus } from '../../main';
// import axios from 'axios';
const provinceData = [
	// {	
	// 	code:'us',
	// 	name:'USD',
	// 	rate:1.0,
	// 	sign:'$'
	// }, 
	// {
	// 	code:'cn',
	// 	name:'RMB',
	// 	rate:0.9,
	// 	sign:'$'
	// }, 
	// {
	// 	code:'rus',
	// 	name:'RSS',
	// 	rate:0.8,
	// 	sign:'$'
	// }
];
//const exchangerateToken = "ece832d6de0e24a67ca73bf7e31da694" //https://manage.exchangeratesapi.io/signup
const exchangerateToken = "dec58f8b997cd71915fc1df9" //https://app.exchangerate-api.com/activate-account
export default ({
		props: {
			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false,
			},
			isWhite: {
				type: Boolean,
				default: false,
			},
            
		},
		data() {
			return {
				// Fixed header/sidebar-footer ( Affix component ) top offset.
				top: 0,
				
				provinceData,
				visibleLogin: false,
				visibleSignup: false,
				visibleForgot: false,
				visibleAgentSignup: false,
				userInfo: {
					name:""
				},
				isLogin:false,
				visibleUserPanel:false,

				logoImage:'images/logo1.png',
				logoName:'VUE COLLECTION',

				visibleMenu: false,
				placementMenu: 'right',

				selectedCurrrency: 'USD',

				exchangerateToken,
				visibleSignUpSuccess:false,
				visibleSignUpSuccessAgent:false,

				visibleLoginSuccess:false,

			}
		},
		components: {
			LoginView,
			SignupView,
			SignupAgentView,
			ForgotView
		},
		created() {
			
			bus.$on('showLogin', (data) => {
				this.visibleLogin = data;
			});
			bus.$on('showSignUp', (data) => {
				this.visibleSignup = data;
			});

			this.selectedCurrrency = 'USD'
					
			this.$store.commit('setCurrency', {
				code:'us',
				name:'USD',
				rate:1.0,
				sign:'$'
			})
			
			// this.getCurrencyInfos();
			this.$on('visibleSignup', section => {
				this.showSignup(section)
			});
			// this.getLogo();
			// this.updateCurrencyDrop();

			
			let urlType = this.$route.query.type;
			if (urlType != undefined && urlType == "login")
				this.visibleLogin = true;

		},

		methods: {
			getCurrencyInfos:async function(){
				const res = await api.getCurrencyInfos(exchangerateToken);
				if (res != undefined)
					this.getCurrencyInfosResult(res);
				
			},
			getCurrencyInfosResult(res){
				const allInfos = res;
				// const usdRate = allInfos.USD;
				const _keys = Object.keys(allInfos)
				this.provinceData = [];
				for (let i = 0; i < _keys.length; i++){
					const _rate = allInfos[_keys[i]];
					const countrycode = common.getCountryFromCurrency(_keys[i])
					this.provinceData.push(
						{
							code: countrycode,
							name: _keys[i],
							rate: _rate,
							sign: '$'
						}
					)
				}
			},
			getLogo:async function(){
				const res = await api.getLogoInfo();
				if (res.length > 0){
					this.logoImage = `${res[0].logoimage}`;
					this.logoName = res[0].logoname;
				}
			},
			showMenu() {
				this.visibleMenu = true;
			},
			onCloseMenu() {
				this.visibleMenu = false;
			},
			gotoAbout(){
				this.$router.push({ path: '/about/'})
				this.visibleMenu = false;
			},
			gotoResorts(){
				this.$router.push({ path: '/resorts/'})
				this.visibleMenu = false;
			},
			gotoInspirations(){
				
				let routeData = this.$router.resolve({ path: '/inspiration/'});
				window.open(routeData.href, '_self');
				// this.$router.push({ path: '/inspiration/'})
				this.visibleMenu = false;
			},
			gotoBestOffers(){
				this.$router.push({ path: '/bestoffers/'})
				this.visibleMenu = false;
			},
			gotoContact(){
				this.$router.push({ path: '/contact/'})
				this.visibleMenu = false;
			},
			updateCurrencyDrop(){
				if (this.$store.state.selectedCurrency !== null){
					this.selectedCurrrency = this.$store.state.selectedCurrency.name;		
				}
				else {
					this.selectedCurrrency = 'USD'
					
					this.$store.commit('setCurrency', {
						code:'us',
						name:'USD',
						rate:1.0,
						sign:'$'
					})
				}
			},


			handleProvinceChange(value) {
				const cellData = this.provinceData.filter(obj => obj.name == value)[0];
				this.$store.commit('setCurrency', {
					code:cellData.code,
					name:value,
					rate:cellData.rate,
					sign:common.getSimbolFromCurrency(value)
				})
				this.$router.go(0)
			},
			onSearch(){
                
			},
			showLogin(_isShow){
				this.visibleLogin = _isShow;
				this.visibleMenu = false;
				if (!_isShow)
					this.updateUserInfo();
			},
			showLoginSuccess(){
                this.visibleLoginSuccess = true;
				this.showLogin(false);
			},
			showSignup(_isShow){
				this.visibleSignup = _isShow;
				this.visibleMenu = false;
				if (!_isShow)
					this.updateUserInfo();
			},
			showSuccess(){
				this.visibleSignup = false;
				this.visibleMenu = false;
				this.visibleSignUpSuccess = true;
				this.updateUserInfo();
			},
			showSuccessAgent(){
				this.visibleAgentSignup = false;
				this.visibleMenu = false;
				this.visibleSignUpSuccessAgent = true;
				this.updateUserInfo();
			},
			showForgot(_isShow){
				this.visibleForgot = _isShow;
				this.visibleMenu = false;
				if (!_isShow)
					this.updateUserInfo();
			},
			showAgentSignup(_isShow){
				this.visibleAgentSignup = _isShow;
			},
			closeShowSignup(){
				this.visibleLogin = false;
				this.visibleForgot = false;
				this.visibleSignup = true;
			},
			closeShowForgot(){
				this.visibleLogin = false;
				this.visibleSignup = false;
				this.visibleForgot = true;
				this.visibleAgentSignup = false;
			},
			closeShowLogin(){
				this.visibleLogin = true;
				this.visibleForgot = false;
				this.visibleSignup = false;
				this.visibleAgentSignup = false;
			},
			closeShowSignAgent(){
				this.visibleLogin = false;
				this.visibleSignup = false;
				this.visibleAgentSignup = true;
			},
			updateUserInfo(){
				if (this.$store.state.loginUser !== null){
					if (this.$store.state.loginUser.loginType === 'api'){
						this.userInfo.name = this.$store.state.loginUser.api.user.name;
						this.userInfo.email = this.$store.state.loginUser.api.user.email;
						this.isLogin = true;
					}
					else if (this.$store.state.loginUser.loginType === 'google'){
						this.userInfo.name = this.$store.state.loginUser.google.user.name;
						this.userInfo.email = this.$store.state.loginUser.google.user.email;
						this.isLogin = true;
					}
					else if (this.$store.state.loginUser.loginType === 'fb'){
						this.userInfo.name = this.$store.state.loginUser.fb.user.name;
						this.isLogin = true;
					}
					else{
						this.userInfo.name = '';
						this.isLogin = false;
					}
				}
				else{
					this.userInfo.name = ''
					this.isLogin = false;
				}
			},
			logout(){
				if (this.$store.state.loginUser.loginType === 'fb'){
					window.FB.logout(function(response) {
					// user is now logged out
						console.log("fb logout", response);
					});
				}
				this.visibleUserPanel = false;
				this.$store.commit('setLoginUser', null)
				this.updateUserInfo();
			},
			showConfirm() {
				this.$confirm({
					title: 'Do you want to logout?',
					content: 'When clicked the OK button, you logout!',
					onOk() {
						this.logout()
					},
					onCancel() {},
				});
			},

		},
		mounted: function(){
            
            // this.$store.commit('setLoginUser', null)
			this.updateUserInfo();
		},
		destroyed() {
            
		},
	})
</script>