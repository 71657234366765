<template>
    <div class="signup">
        <div>
            <div style="width:100%;display:flex;">
                <div class="loginDialog">
                    <div class="loginBtnMain">
                        <div style="display:flex">
                            <div class="loginTitle">Sign up</div>
                            <a-icon style="margin-right:0;margin-left:auto" type="close" @click="$emit('close')" :style="{ fontSize: '24px', color:'#AAAAAA' }"/>
                        </div>
                        <div class="signupText1" style="white-space: nowrap;">To get access to great rates and offers to the Collection. </div>
                        <div class="signupText2">AGENT INFORMATION:</div>
                        
                        <!-- <a-select :default-value="titleList[0]" v-model="signupUser.title" placeholder="Title"  style="width:100%;margin-top:25px;font-family: Montserrat light;" :size="'large'">
                            <a-select-option v-for="_title in titleList" :key="_title">
                                <div style="display:flex">
                                    <span style="margin-top:auto;margin-bottom:auto;">{{ _title }}</span>
                                </div>
                            </a-select-option>
                        </a-select> -->

                        <div style="display:flex">
                            <a-input class="signupInput1" v-model="signupUser.firstName" @change="inputChange" placeholder="First Name" :size="'large'"/>
                            <a-input class="signupInput2" v-model="signupUser.lastName" @change="inputChange" placeholder="Last Name" :size="'large'"/>
                        </div>
                        <a-input class="loginInput" v-model="signupUser.phoneNumber" @change="inputChange" placeholder="Phone Number" :size="'large'"/>
                        <div class="signupText2">BUSINESS INFORMATION:</div>
                        <a-input class="loginInput" v-model="signupUser.agencyName" @change="inputChange" placeholder="Agency name" :size="'large'"/>
                        <a-input class="loginInput" v-model="signupUser.menberName" @change="inputChange" placeholder="Please indicate consortia member, if any (i.e. virtuoso)" :size="'large'"/>
                        <a-input class="loginInput" v-model="signupUser.businessID" @change="inputChange" placeholder="Business ID # (i.e. IATA, CLIA etc)" :size="'large'"/>
                        <a-input class="loginInput" v-model="signupUser.stateProvince" @change="inputChange" placeholder="State/Province of business" :size="'large'"/>
                        
                        <a-auto-complete
                            :data-source="countryList"
                            style="width:100%;margin-top:25px"
                            placeholder="Country of business"
                            v-model="signupUser.country"
                            :size="'large'"
                            @select="onSelectCountry"
                            @blur="onBlurCountry"
                            :filter-option="filterOption"
                        />
                        <!-- <a-select mode="combobox" :default-value="countryList[0]" v-model="signupUser.country" placeholder="Country of business" style="width:100%;margin-top:25px" @change="handlePersonType" :size="'large'">
                            <a-select-option v-for="_country in countryList" :key="_country">
                                <div style="display:flex">
                                    <span style="margin-top:auto;margin-bottom:auto;">{{ _country }}</span>
                                </div>
                            </a-select-option>
                        </a-select> -->
                        <div class="signupText2">SIGN UP:</div>
                        <a-input class="loginInput" v-model="signupUser.email" @change="inputChange" placeholder="Email" :size="'large'"/>
                        
                        <div style="position: relative;">
                            <a-input class="loginInput" v-model="signupUser.password" type="password" @change="inputChange" placeholder="Password" :size="'large'"/>
                            <a-popover placement="bottomLeft" class="StarOverDiv" style="position: absolute;bottom:0;left:-20px">
                                <template slot="content">
                                    <div class="StarOverDivCell" style="text-align:left;">
                                        Password guidelines:
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must contain one uppercase and lowercase letter
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must include at least one number
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must be at least 8 characters in length
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must not contain the username
                                        </div>
                                    </div>
                                </template>
                                <img style="cursor:pointer" src="../assets/images/passwordInfo.png" alt="" width="16">
                            </a-popover>
                        </div>

                        
                        <a-checkbox class="signupAgreeText" :checked="checked" @change="onChange">
                            <span class="signupAgreeTextMain">
                                I agree to <a class="signupAgreeTextTermsMain" onclick="window.open('/termscondition/', '_blank');">Terms & Conditions</a>, 
                                <a class="signupAgreeTextTermsMain" onclick="window.open('/privacypolicy/', '_blank');">Privacy Policy</a> and subscribe to emails for offers and updates.
                            </span>
                        </a-checkbox>

                        <!-- <div  class="loginRecaptchaDiv">
                            <vue-recaptcha class="loginRecaptcha" sitekey="6LcZKt0eAAAAALs9c2BYKyPbgggQAu4WY4PxVCqg" 
                                @verify="verifyMethod" 
                                @expired="expiredMethod"
                                @render="renderMethod"
                                @error="errorMethod">
                            </vue-recaptcha>
                        </div> -->
                        <a-button id="loginBtn" class="loginBtn" :disabled = "disabled"  type="primary" :size="'large'" :style="{ fontSize: '17px'}" @click="handleSignupClick">
                            Create account
                        </a-button>
                        
                        <div class="loginDialogLine"></div>
                        <div class="loginBtnSignUp">Already a member? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
// import SocialLogin from '@/components/SocialLogin'
// import { VueRecaptcha } from 'vue-recaptcha';
  const countryList = ['Country of business', 'CH', 'RSS'];
//   const stateList = ['State/Province of business', 'CH', 'RSS'];
  const titleList=['Mr', 'Mrs', 'Ms', 'Mx'];
export default {
    
    data() {
        return {
            checked: false,
            disabled: false,
            countryList,
            titleList,
            // stateList,
            signupUser: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        firstName: '',
                        lastName: '',
                        email:'',
                        password:'',
                        permissionLevel:1,
                        country:'',
                        title:'',
                        phoneNumber:'',
                        agencyName:'',
                        menberName:'',
                        businessID:'',
                        stateProvince:''
                    };
                }
            },
            
            isVerify: false,
        }
    },
    components: {
        // SocialLogin,
        // VueRecaptcha
    },
    methods: {
        close(){
            this.$emit('close');
        },
        
    // eslint-disable-next-line no-unused-vars
        onSelectCountry(value) {
            this.updateButton();
        }, 
        onBlurCountry() {
            if (!this.countryList.includes(this.signupUser.country))
                this.signupUser.country = '';
        }, 
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
            );
        },
        onChange(e) {
            this.checked = e.target.checked;
            this.updateButton();
        },
        handleSignupClick: async function() {
            
            if (this.signupUser.firstName == ""){
                this.$message.error('Please input first name.');
                return
            }
            if (this.signupUser.lastName == ""){
                this.$message.error('Please input last name.');
                return
            }
            // eslint-disable-next-line no-useless-escape
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (!regex.test(this.signupUser.email.toLowerCase())){
                this.$message.error('Please check your email address.');
                return
            }
            if (this.signupUser.country == ""){
                this.$message.error('Please select your country.');
                return
            }
            if (!this.checkPassword()){
                return
            }
            // const myMessageError = (content, duration, onClose) => {
            //     let _remove
            //     const h = this.$createElement
            //     let innerText = h('span', { style: { color: '#000000A6' } }, content);
            //     let innerIcon = h('a-icon', {
            //     style: { marginRight: '0px', marginLeft: '20px', color: '#ccc', cursor: 'pointer' },
            //     attrs: { type: 'close' },
            //     on: { click: () => _remove() }
            //     })
            //     let container = h('span', {}, [innerText, innerIcon])
            //     _remove = this.$message.success({
            //         content: container,
            //         duration: duration || 0,
            //         onClose: onClose,
            //     })
            // }
            
            this.signupUser.permissionLevel = 4;
            this.signupUser['redirecturl'] = window.location.host;
            const res = await api.signup(this.signupUser);
            this.flash('task created', 'success');
            //if (res.id != undefined){
            if (res != undefined && res != "" && res.error == undefined){
                // myMessageError('We will verify your information within 24 hours. Once approved we will get back to you via email to access our amazing rates!')
                // this.$message.success('We will verify your information within 24 hours. Once approved we will get back to you via email to access our amazing rates!', 0);
                // this.$emit('close');
                
                this.$emit('success');
            }
            else{
                if (res.error == undefined)
                    this.$message.error('This email is already registered');
                else{
                    if (res.error.error_code == "ERROR_CONTACT_EXISTS"){
                        this.$message.error(`${this.signupUser.email} is already a member. Please proceed to sign-in.`);
                    }
                    else{
                        this.$message.error(res.error.error);
                    }
                }
            }
        },
        
        checkPassword(){
            if (this.signupUser.password.length < 8){
                this.$message.error("Password must be at least 8 characters in length");
                return false;
            }
            ////  (?=.*?[#?!@$%^&*-])
            // eslint-disable-next-line no-useless-escape
            let regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
            if (!regex.test(this.signupUser.password)){
                this.$message.error('Password must include at least one number, one uppercase letter, and one lowercase letter');
                return false;
            }
            if (this.signupUser.password.includes(this.signupUser.firstName) || this.signupUser.password.includes(this.signupUser.lastName)){
                this.$message.error("Password must not contain the username");
                return false;
            }
            return true;
            // -Is case sensitive
            // -Can contain letters (a-z), numbers, and special characters
            // -Must be at least 8 characters in length
            // -Must include at least one number, one uppercase letter, and one lowercase letter
            // -Must not contain the username
        },
        inputChange(){
            this.updateButton();
        },
        updateButton(){
            if (this.signupUser.firstName == undefined || this.signupUser.lastName == undefined ||
                this.signupUser.country == undefined || this.signupUser.email == undefined || 
                this.signupUser.password == undefined || !this.checked || this.isVerify)
                this.disabled = true;
            else
                this.disabled = false;
        },
        closeDialog(){
            // this.$router.go(-1)
            window.open("/","_self");// "_blank"
        },
        clickLogin(){
            this.$emit('openLogin');
        },

        openForgotPassword(){
            this.$emit('openForgot');
        },
        
        onEvent() {
            // when you need a reCAPTCHA challenge
            this.$refs.recaptcha.execute();
        },
        verifyMethod(){
            this.isVerify = true;
            this.updateButton();
        },
        expiredMethod(){
            this.isVerify = false;
            this.updateButton();
        },
        renderMethod(){
            this.isVerify = false;
        },
        errorMethod(){

        }
    },
    created(){
        this.countryList = common.getCountryList();
    },
    mounted(){
        this.updateButton();
    }
}
</script>