<template>
  <component :is="'div'">
    <!-- Layout Footer  :offset-top="top"-->

    <a-layout-footer class="footerMainDiv">
      <div id="invtrflfloatbtn"></div>
      <div class="footerSubDiv">
        <div class="footerContentDiv">
          <div>
            <img
              class="logoImageFooter"
              onclick="location.href='/';"
              :src="logoImage"
            />
          </div>
          <div class="footerContentLinksDiv">
            <div class="quickLinks font-new">Quick Links</div>

            <div class="quickLinksDiv">
              <div
                v-for="(item, index) in linkList"
                :key="index"
                class="quickLinkCell font-new"
                @click="gotoQuickLink(item.url)"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="footerIconBar">
              <div class="footerIconBarDiv" v-for="item in socialInfoList"
              :key="item.key">
               <a :href="item.link">
                  <img
                    class="footerIconBarImg"
                    :src="
                      item.icon != '' && item.icon != undefined
                        ? `${item.icon}`
                        : ''
                    "
                    @click="gotoSocialPage(item.link)"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="footerEmailSubscibeDiv">
            <div class="footerSignUpText font-new">
              Sign up to receive latest offers
            </div>
            <div class="footerEmailSubscibeParent">
              <a-input
                class="footerEmailInput font-new"
                v-model="subscribeEmail"
                placeholder="Email address*"
                :size="'large'"
              />
            </div>
            <div class="footerEmailSubscibeParent">
              <div
                v-if="!sendingSubscribe"
                class="footerEmailSubscibe font-new"
                @click="onSubscribe"
              >
                Subscribe
              </div>
              <div v-else class="footerEmailSubscibeDisable" style="">
                Subscribe
              </div>
            </div>
          </div>
        </div>
        <div class="footerEmailLineDiv"></div>
        <div class="footerDiv">
          <div class="footerLastText font-new">
            ©{{ currentYear }} Vue Collection. All rights reserved.
          </div>
          <div class="footerLastText font-new">
            <div style="margin-right: 5px">Created by</div>
            <a
              class="footer-link"
              href="https://vuelifestyle.com/"
              target="_blank"
              >Vuelifestyle.</a
            >
          </div>
        </div>
      </div>
    </a-layout-footer>
  </component>
  <!-- / Main Sidebar -->
</template>

<script>
import { api } from "../../helpers/Helpers";
import { bus } from "../../main";
import moment from "moment";
import validator from "validator";
export default {
  props: {
    // Header fixed status.
    // navbarFixed: {
    // 	type: Boolean,
    // 	default: false,
    // },
  },
  data() {
    return {
      // Fixed header/sidebar-footer ( Affix component ) top offset.
      top: 0,
      nowYear: 2021,
      socialInfoList: [
        {
          _id: 0,
          icon: "",
          url: "",
        },
      ],
      subscribeEmail: "",
      logoImage: "/images/logo1.png",
      logoName: "MALDIVES",
      linkList: [
        {
          name: "About",
          url: "/about/",
        },
        {
          name: "Resorts",
          url: "/resorts/",
        },
        {
          name: "Contact",
          url: "/contact/",
        },
        {
          name: "Login",
          url: "login",
        },
        {
          name: "Sign Up",
          url: "signup",
        },
        {
          name: "Privacy Policy",
          url: "/privacypolicy/",
        },
        {
          name: "Terms & Conditions",
          url: "/termscondition/",
        },
        // {
        // 	name:'FAQ’s',
        // 	url:'',
        // },
      ],

      sendingSubscribe: false,

      currentYear: "",
    };
  },
  methods: {
    moment,
    resizeEventHandler() {},
    onSearch() {},
    gotoTerms() {
      // this.$router.push({ path: '/termscondition/'})
      window.open("/termscondition", "_blank"); // "_blank"
    },
    gotoPolicy() {
      // this.$router.push({ path: '/privacypolicy/'})
      window.open("/privacypolicy", "_blank"); // "_blank"
    },
    gotoContact() {
      this.$router.push({ path: "/contact/" });
    },
    gotoSocialPage(_url) {
      if (_url != "" && _url != undefined) {
        window.open(_url, "_blank");
      }
    },

    getLogo: async function () {
      const res = await api.getLogoInfo();
      if (res.length > 0) {
        this.logoImage = `${res[0].logoimage}`;
        this.logoName = res[0].logoname;
      }
    },
    getSocialIcons: async function () {
      const res = await api.getSocialInfo();
      if (res.length > 0) {
        this.socialInfoList = res[0].datalist;
      }
    },
    onSubscribe: async function () {
      this.sendingSubscribe = true;
      if (this.subscribeEmail == "" || !validator.isEmail(this.subscribeEmail)) {
        this.$message.error("Please input valid email.");
        this.sendingSubscribe = false;
      } else {
        const subscribeInfo = {
          email: this.subscribeEmail,
          password: "",
          firstName: "Guest",
          lastName: "Guest",
          country: "United States of America",
          permissionLevel: 1,
        };
        const res = await api.subscribe(subscribeInfo);
        if (res != undefined && res != "") {
          this.$message.success("Subscribe success!");
          this.$emit("close");
        } else {
          this.$message.error("This email is already using by other");
        }
        this.sendingSubscribe = false;
      }
    },
    gotoQuickLink(_url) {
      if (_url == "login") {
        bus.$emit("showLogin", true);
      } else if (_url == "signup") {
        bus.$emit("showSignUp", true);
      } else {
        // this.$router.push({ path: _url});
        window.open(_url, "_blank"); // "_blank"
      }
    },
  },
  mounted: function () {
    this.currentYear = moment(new Date()).format("YYYY");
  },
  created() {
    var nowDate = new Date();
    this.nowYear = nowDate.getFullYear();
    this.getSocialIcons();
    // this.getLogo();
  },
  destroyed() {},
};

var ir =
  ir ||
  function () {
    (window.ir.q = window.ir.q || []).push(arguments);
  };
var invite_referrals = window.invite_referrals || {};
(function () {
  invite_referrals.auth = {
    bid_e: "5FEC791452A7F3677618B663E2BC62C7",
    bid: "67653",
    t: "420",
    email: "",
    userParams: { fname: "" },
  };
  invite_referrals.async = false;
  var script = document.createElement("script");
  script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";

  var entry = document.getElementsByTagName("script")[0];
  entry.parentNode.insertBefore(script, entry);
})();
</script>
