export const common = {
    
    getLoginState(store){
        var userInfo = {};
        var isLogin = false;
        if (store.state.loginUser !== null){
            if (store.state.loginUser.loginType === 'api'){
                userInfo['name'] = store.state.loginUser.api.user.name;
                userInfo['email'] = store.state.loginUser.api.user.email;
                isLogin = true;
            }
            else if (store.state.loginUser.loginType === 'google'){
                userInfo['name'] = store.state.loginUser.google.user.name;
                userInfo['email'] = store.state.loginUser.google.user.email;
                isLogin = true;
            }
            else if (store.state.loginUser.loginType === 'fb'){
                userInfo['name'] = store.state.loginUser.fb.user.name;
                isLogin = true;
            }
            else{
                userInfo['name'] = '';
                isLogin = false;
            }
        }
        else{
            userInfo['name'] = ''
            isLogin = false;
        }
        return isLogin;
    },
    getResortSize(_key) {
        const resortSizeList = {
            0:'Intimate (less < 50 rooms)', 
            1:'Moderate (more > 50 rooms)',
            2:'Extensive (more > 100 rooms)'
        }
        return resortSizeList[_key];
    },
    
    getResortStyle(_key) {
        const resortStyleList = {
            0:'Modern',
            1:'Barefoot luxury',
            2:'Rustic chic',
            3:'Ultra-luxury',
            4:'Elegant',
            5:'Maldivian & Modern',
            6:'Maldivian rustic',
        }
        return resortStyleList[_key];
    },
    
    getResortBestFor(_key) {
        const resortList = {
            0:'Romance',
            1:'Family',
            2:'Friends getaway',
            3:'Solo traveler',
            4:'Foodies',
            5:'Spa & wellness',
            6:'Wellness retreat',
            7:'All-inclusive',
            8:'Best for diving',
            9:'Great house reef',
            10:'Snorkeling',
            11:'Quick airport transfer',
            12:'Underwater dining',
            13:'Overwater villas only',
            14:'Adults-only resort',
            15:'Best for surfing',
            16:'Remote and secluded',            
        }
        return resortList[_key];
    },
    
    getCountryList() {
        const countryList = [
            "United States of America",
            "Aaland Islands",
            "Afghanistan",
            "Algeria",
            "American Samoa",
            "Andorra",
            "Angola",
            "Anguilla",
            "Antarctica",
            "Antigua And Barbuda",
            "Argentina",
            "Armenia",
            "Aruba",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bermuda",
            "Bhutan",
            "Bolivia",
            "Bonaire, Saint Eustatius and Saba",
            "Bosnia and Herzegovina",
            "Botswana",
            "Bouvet Island",
            "Brazil",
            "British Indian Ocean Territory",
            "Brunei Darussalam",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Cape Verde",
            "Cayman Islands",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Christmas Island",
            "Cocos (Keeling) Islands",
            "Colombia",
            "Comoros",
            "Congo",
            "Cook Islands",
            "Costa Rica",
            "Cote D'Ivoire",
            "Croatia",
            "Cuba",
            "Curacao",
            "Cyprus",
            "Czech Republic",
            "Democratic Republic of the Congo",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Ethiopia",
            "Falkland Islands",
            "Faroe Islands",
            "Fiji",
            "Finland",
            "France",
            "French Guiana",
            "French Polynesia",
            "French Southern Territories",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Gibraltar",
            "Greece",
            "Greenland",
            "Grenada",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernsey",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Heard and Mc Donald Islands",
            "Honduras",
            "Hong Kong",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Isle of Man",
            "Israel",
            "Italy",
            "Jamaica",
            "Japan",
            "Jersey  (Channel Islands)",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kuwait",
            "Kyrgyzstan",
            "Lao People's Democratic Republic",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Macau",
            "Macedonia",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Collection",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Martinique",
            "Mauritania",
            "Mauritius",
            "Mayotte",
            "Mexico",
            "Micronesia, Federated States of",
            "Moldova, Republic of",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Montserrat",
            "Morocco",
            "Mozambique",
            "Myanmar",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "Netherlands Antilles",
            "New Caledonia",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "Niue",
            "Norfolk Island",
            "North Korea",
            "Northern Mariana Islands",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Pitcairn",
            "Poland",
            "Portugal",
            "Puerto Rico",
            "Qatar",
            "Republic of Kosovo",
            "Reunion",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Martin",
            "Saint Vincent and the Grenadines",
            "Samoa (Independent)",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Sint Maarten",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Georgia and the South Sandwich Islands",
            "South Korea",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "St. Helena",
            "St. Pierre and Miquelon",
            "Sudan",
            "Suriname",
            "Svalbard and Jan Mayen Islands",
            "Swaziland",
            "Sweden",
            "Switzerland",
            "Syria",
            "Taiwan",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Turks &amp; Caicos Islands",
            "Turks and Caicos Islands",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "Uruguay",
            "USA Minor Outlying Islands",
            "Uzbekistan",
            "Vanuatu",
            "Vatican City State (Holy See)",
            "Venezuela",
            "Vietnam",
            "Virgin Islands (British)",
            "Virgin Islands (U.S.)",
            "Wallis and Futuna Islands",
            "Western Sahara",
            "Yemen",
            "Zambia",
            "Zimbabwe",

        ]
        return countryList;
    },
    getCountryFromCurrency(_key){
        let codeToCountry = {
            "AED" : "ae",
            "AFN" : "af",
            "ALL" : "ax",
            "AMD" : "am",
            "ANG" : "nl",
            "AOA" : "ao",
            "ARS" : "ar",
            "AUD" : "au",
            "AWG" : "aw",
            "AZN" : "az",
            "BAM" : "ba",
            "BBD" : "bb",
            "BDT" : "bd",
            "BGN" : "bg",
            "BHD" : "bh",
            "BIF" : "bi",
            "BMD" : "bm",
            "BND" : "bn",
            "BOB" : "bo",
            "BOV" : "bo",
            "BRL" : "br",
            "BSD" : "bs",
            "BTN" : "bt",
            "BWP" : "bw",
            "BYR" : "by",
            "BYN" : "by",
            "BZD" : "bz",
            "CAD" : "ca",
            "CDF" : "cd",
            "CHE" : "ch",
            "CHF" : "li",
            "CHW" : "ch",
            "CLF" : "cl",
            "CLP" : "cl",
            "CNY" : "cn",
            "COP" : "co",
            "COU" : "co",
            "CRC" : "cr",
            "CUP" : "cu",
            "CVE" : "cv",
            "CYP" : "cy",
            "CZK" : "cz",
            "DJF" : "dj",
            "DKK" : "dk",
            "DOP" : "do",
            "DZD" : "dz",
            "EEK" : "ee",
            "EGP" : "eg",
            "ERN" : "er",
            "ETB" : "et",
            "EUR" : "eu",
            "FJD" : "fj",
            "FKP" : "fk",
            "FOK" : "fo",
            "GBP" : "gb",
            "GGP" : "gg",
            "GEL" : "ge",
            "GHS" : "gh",
            "GIP" : "gi",
            "GMD" : "gm",
            "GNF" : "gn",
            "GTQ" : "gt",
            "GYD" : "gy",
            "HKD" : "hk",
            "HNL" : "hn",
            "HRK" : "hr",
            "HTG" : "ht",
            "HUF" : "hu",
            "IDR" : "id",
            "ILS" : "il",
            "IMP" : "il",
            "INR" : "in",
            "IQD" : "iq",
            "IRR" : "ir",
            "ISK" : "is",
            "JEP" : "je",
            "JMD" : "jm",
            "JOD" : "jo",
            "JPY" : "jp",
            "KES" : "ke",
            "KGS" : "kg",
            "KHR" : "kh",
            "KID" : "ki",
            "KMF" : "km",
            "KPW" : "kp",
            "KRW" : "kr",
            "KWD" : "kw",
            "KYD" : "ky",
            "KZT" : "kz",
            "LAK" : "la",
            "LBP" : "lb",
            "LKR" : "lk",
            "LRD" : "lr",
            "LSL" : "ls",
            "LTL" : "lt",
            "LVL" : "lv",
            "LYD" : "ly",
            "MAD" : "ma",
            "MDL" : "md",
            "MGA" : "mg",
            "MKD" : "mk",
            "MMK" : "mm",
            "MNT" : "mn",
            "MOP" : "mo",
            "MRO" : "mr",
            "MRU" : "mr",
            "MTL" : "mt",
            "MUR" : "mu",
            "MVR" : "mv",
            "MWK" : "mw",
            "MXN" : "mx",
            "MXV" : "mx",
            "MYR" : "my",
            "MZN" : "mz",
            "NAD" : "na",
            "NGN" : "ng",
            "NIO" : "ni",
            "NOK" : "no",
            "NPR" : "np",
            "NZD" : "ck",
            "OMR" : "om",
            "PAB" : "pa",
            "PEN" : "pe",
            "PGK" : "pg",
            "PHP" : "ph",
            "PKR" : "pk",
            "PLN" : "pl",
            "PYG" : "py",
            "QAR" : "qa",
            "RON" : "ro",
            "RSD" : "rs",
            "RUB" : "ru",
            "RWF" : "rw",
            "SAR" : "sa",
            "SBD" : "sb",
            "SCR" : "sc",
            "SDG" : "ss",
            "SSP" : "ss",
            "SEK" : "se",
            "SGD" : "sg",
            "SHP" : "sh",
            "SKK" : "sk",
            "SLL" : "sl",
            "SOS" : "so",
            "SRD" : "sr",
            "STD" : "st",
            "STN" : "st",
            "SYP" : "sy",
            "SZL" : "sz",
            "THB" : "th",
            "TJS" : "tj",
            "TMM" : "tm",
            "TMT" : "tm",
            "TND" : "tn",
            "TOP" : "to",
            "TRY" : "tr",
            "TTD" : "tt",
            "TVD" : "tv",
            "TWD" : "tw",
            "TZS" : "tz",
            "UAH" : "ua",
            "UGX" : "ug",
            "USD" : "us",
            "USN" : "us",
            "USS" : "us",
            "UYU" : "uy",
            "UZS" : "uz",
            "VEB" : "ve",
            "VES" : "ve",
            "VND" : "vn",
            "VUV" : "vu",
            "WST" : "ws",
            "XAF" : "cm",
            "XAG" : "",
            "XAU" : "",
            "XBA" : "",
            "XBB" : "",
            "XBC" : "",
            "XBD" : "",
            "XCD" : "ai",
            "XDR" : "gb-wls",
            "XFO" : "",//"Bank for International Settlements",
            "XFU" : "",//"International Union of Railways",
            "XOF" : "bj",//"Benin, Burkina Faso, Côte d'Ivoire, Guinea-Bissau, Mali, Niger, Senegal, Togo",
            "XPD" : "",
            "XPF" : "pf",//"French Polynesia, New Caledonia, Wallis and Futuna",
            "XPT" : "",
            "XTS" : "",
            "XXX" : "",
            "YER" : "ye",
            "ZAR" : "za",//"South Africa",
            "ZMK" : "zm",
            "ZMW" : "zm",
            "ZWD" : "zw",//"Zimbabwe"
            "ZWL" : "zw",//"Zimbabwe"
        }
        return codeToCountry[_key];
    },
    getSimbolFromCurrency(_key){
        let codeToSimbols = {
            "AED" : "د.إ",
            "AFN" : "؋",
            "ALL" : "Lek",
            "AMD" : "֏",
            "ANG" : "ƒ",
            "AOA" : "Kz",
            "ARS" : "$",
            "AUD" : "$",
            "AWG" : "ƒ",
            "AZN" : "₼",
            "BAM" : "KM",
            "BBD" : "$",
            "BDT" : "৳",
            "BGN" : "лв",
            "BHD" : "BD",
            "BIF" : "FBu",
            "BMD" : "$",
            "BND" : "$",
            "BOB" : "$b",
            "BOV" : "$b",
            "BRL" : "R$",
            "BSD" : "$",
            "BTN" : "Nu.",
            "BWP" : "P",
            "BYR" : "Br",
            "BYN" : "Br",
            "BZD" : "BZ$",
            "CAD" : "$",
            "CDF" : "FC",
            "CHE" : "£",
            "CHF" : "CHF",
            "CHW" : "£",
            "CLF" : "$",
            "CLP" : "$",
            "CNY" : "¥",
            "COP" : "$",
            "COU" : "$",
            "CRC" : "₡",
            "CUP" : "₱",
            "CVE" : "$",
            "CYP" : "£",
            "CZK" : "Kč",
            "DJF" : "Fdj",
            "DKK" : "kr",///////////////
            "DOP" : "RD$",
            "DZD" : "دج",
            "EEK" : "kr",
            "EGP" : "£",
            "ERN" : "Hfk",
            "ETB" : "Br",
            "EUR" : "€",
            "FJD" : "$",
            "FKP" : "£",
            "FOK" : "kr",
            "GBP" : "£",
            "GGP" : "£",
            "GEL" : "ლ",
            "GHS" : "¢",
            "GIP" : "£",
            "GMD" : "D",
            "GNF" : "FG",
            "GTQ" : "Q",
            "GYD" : "$",
            "HKD" : "$",
            "HNL" : "L",
            "HRK" : "kn",
            "HTG" : "G",
            "HUF" : "Ft",
            "IDR" : "Rp",
            "ILS" : "₪",
            "IMP" : "£",
            "INR" : "₹",
            "IQD" : "ع.د",
            "IRR" : "﷼",
            "ISK" : "kr",//////////////////////
            "JEP" : "£",
            "JMD" : "J$",
            "JOD" : "د.ا",
            "JPY" : "¥",
            "KES" : "K",
            "KGS" : "лв",
            "KHR" : "៛",
            "KID" : "$",
            "KMF" : "CF",
            "KPW" : "₩",
            "KRW" : "₩",
            "KWD" : "KD",
            "KYD" : "$",
            "KZT" : "лв",
            "LAK" : "₭",
            "LBP" : "£",
            "LKR" : "₨",
            "LRD" : "$",
            "LSL" : "L",
            "LTL" : "Lt",
            "LVL" : "ℒ𝓈",
            "LYD" : "LD",
            "MAD" : "MAD",
            "MDL" : "L",
            "MGA" : "Ar",
            "MKD" : "ден",
            "MMK" : "K",
            "MNT" : "₮",
            "MOP" : "MOP$",
            "MRO" : "UM",
            "MRU" : "UM",
            "MTL" : "₤M",
            "MUR" : "₨",
            "MVR" : "Rf",
            "MWK" : "MK",
            "MXN" : "$",
            "MXV" : "$",
            "MYR" : "RM",
            "MZN" : "MT",
            "NAD" : "$",
            "NGN" : "₦",
            "NIO" : "C$",
            "NOK" : "kr",//////
            "NPR" : "₨",
            "NZD" : "$",
            "OMR" : "﷼",
            "PAB" : "B/.",
            "PEN" : "S/.",
            "PGK" : "K",
            "PHP" : "₱",
            "PKR" : "₨",
            "PLN" : "zł",
            "PYG" : "Gs",
            "QAR" : "﷼",
            "RON" : "lei",
            "RSD" : "Дин",
            "RUB" : "₽",
            "RWF" : "FRw",
            "SAR" : "﷼",
            "SBD" : "$",
            "SCR" : "₨",
            "SDG" : "ج.س.",
            "SSP" : "£",
            "SEK" : "kr",/////////
            "SGD" : "$",
            "SHP" : "£",
            "SKK" : "Sk",
            "SLL" : "Le",
            "SOS" : "S",
            "SRD" : "$",
            "STD" : "Db",
            "STN" : "Db",
            "SYP" : "£",
            "SZL" : "E",
            "THB" : "฿",
            "TJS" : "ЅM",
            "TMM" : "T",
            "TMT" : "T",
            "TND" : "DT",
            "TOP" : "T$",
            "TRY" : "₺",
            "TTD" : "TT$",
            "TVD" : "$",
            "TWD" : "NT$",
            "TZS" : "TSh",
            "UAH" : "₴",
            "UGX" : "USh",
            "USD" : "$",
            "USN" : "$",
            "USS" : "$",
            "UYU" : "$U",
            "UZS" : "лв",
            "VEB" : "Bs",
            "VES" : "Bs",
            "VND" : "₫",
            "VUV" : "VT",
            "WST" : "WS$",
            "XAF" : "FCFA",
            "XAG" : "Ag",
            "XAU" : "Gold",
            "XBA" : "XBA",
            "XBB" : "XBB",
            "XBC" : "XBC",
            "XBD" : "XBD",
            "XCD" : "$",
            "XDR" : "gb-wls",
            "XFO" : "XFO",//"Bank for International Settlements",
            "XFU" : "XFU",//"International Union of Railways",
            "XOF" : "bj",//"Benin, Burkina Faso, Côte d'Ivoire, Guinea-Bissau, Mali, Niger, Senegal, Togo",
            "XPD" : "Ounce",
            "XPF" : "pf",//"French Polynesia, New Caledonia, Wallis and Futuna",
            "XPT" : "Ounce",
            "XTS" : "XTS",
            "XXX" : "XXX",
            "YER" : "﷼",
            "ZAR" : "R",//"South Africa",
            "ZMK" : "ZK",
            "ZMW" : "ZK",
            "ZWD" : "Z$",//"Zimbabwe"
            "ZWL" : "Z$",//"Zimbabwe"
        }
        return codeToSimbols[_key];
    }
}