import Vue from 'vue'
import responsive from 'vue-responsive'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import 'semantic-ui-css/semantic.css';
import router from './Router'
import store from './store'

import VueMq from 'vue-mq'
import VueCardFormat from 'vue-credit-card-validation';


import VueSocialSharing from 'vue-social-sharing'

// import CountryFlag from 'vue-country-flag'
import FlagIcon from 'vue-flag-icon'

import { StripePlugin } from '@vue-stripe/vue-stripe';
const options = {
  pk: 'pk_live_lC6hzigLgulKkvIozhW3AiHo00hsAzUryW',
  stripeAccount: undefined,
  apiVersion: undefined,
  locale: 'auto',
};

import "./css/common.css";
import "./css/header.css"
import "./css/footer.css"
import "./css/customAntd.css"
import "./css/home.css"
import "./css/about.css"
import "./css/resorts.css"
import "./css/resorts2.css"
import "./css/inspirations.css"
import "./css/contact.css"
import "./css/hotelDetail.css"
import "./css/checkout.css"
import "./css/bookingsummary.css"
import "./css/login.css"
import "./css/signup.css"
import "./css/listitems.css"
import "./css/PrivacyPolicy.css"
import "./css/contactusviwer.css"
import "./css/instagramviewer.css"
import "./css/others.css"
import "./css/bestoffers.css"

import "./css/searchroomtabcell.css"
import "./css/hotelselectroom.css"
import "./css/hotelenhancepart.css"


import GoogleAuth from '@/config/google_oAuth.js'

// import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '101176899724-7ac23fke4oa6el2htmt1mhcighkt12m5.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

import FacebookAuth from 'vue-facebook-oauth'


import Meta from 'vue-meta';
Vue.use(Meta);

// Vue.use(FacebookAuth, { appId: '1392572597849642', version: 'v1.0' });
Vue.use(StripePlugin, options);
Vue.use(FacebookAuth, { appId: '1394910031005898', version: 'v1.0' });
Vue.facebookAuth().load();
Vue.use(responsive)
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    ssm: 750,
    md: 1250,
    lg: Infinity,
  }
})

Vue.use(Antd);
Vue.config.productionTip = false
Vue.use(FlagIcon);
Vue.use(VueCardFormat);
// Vue.component('country-flag', CountryFlag)


Vue.use(VueSocialSharing);

export const bus = new Vue();

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 3,
  attempt: 3,
  lazyComponent: true
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
