import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '@/config/utils'

Vue.use(Vuex)
const user = getStore('user');
const currency = getStore('currency');
const bookListInfo = getStore('bookListInfo');

export default new Vuex.Store({
  state: {
    loginUser: user,
    selectedCurrency: currency,
    selecetedBookListInfo: bookListInfo
  },
  mutations: {
    setLoginUser(state, user) {
      state.loginUser = user
      setStore('user', user)
    },
    setCurrency(state, currency) {
      state.selectedCurrency = currency
      setStore('currency', currency)
    },
    setBookListInfo(state, bookListInfo) {
      state.selecetedBookListInfo = bookListInfo
      setStore('bookListInfo', bookListInfo)
    }
  },
  actions: {
  },
  modules: {
    getLoginUserInfo(state) {
      return state.loginUser
    },
    getCurrencyInfo(state) {
      return state.selectedCurrency
    },
    getBookListInfo(state) {
      return state.selecetedBookListInfo
    }
  }
})
