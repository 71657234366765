<template>
    <div class="login">
        <div>
            <div class="loginDialogDiv">
                <div class="loginDialog">
                    <div class="loginBtnMain">
                        <div class="loginTopDiv">
                            <div class="loginTitle">Sign In</div>
                            <a-icon class="loginCloseIcon" type="close" @click="$emit('close')" :style="{ fontSize: '24px', color:'#AAAAAA' }"/>
                        </div>
                        <a-input class="loginInput" placeholder="Email" v-model="loginUser.email" :size="'large'" @change="emailChanged"/>
                        <a-input-password class="loginInput" placeholder="Password" v-model="loginUser.password" :size="'large'" @change="emailChanged"/>
                        <!-- <div class="loginRecaptchaDiv">
                            <vue-recaptcha class="loginRecaptcha" sitekey="6LcZKt0eAAAAALs9c2BYKyPbgggQAu4WY4PxVCqg" 
                                @verify="verifyMethod" 
                                @expired="expiredMethod"
                                @render="renderMethod"
                                @error="errorMethod">
                            </vue-recaptcha>
                        </div> -->
                        

                        <a-button class="loginBtn" type="primary" :size="'large'" :style="{ fontSize: '17px'}"  @click="handleLoginClick">
                            Sign in
                        </a-button>
                        <div class="forgotPassBtn" @click="openForgotPassword">Forgot password?</div>
                        <div v-if="false" class="loginText">Or continue with:</div>
                        
                        <div v-if="false" style="display:flex;margin-top:10px">
                            <!-- <img style="width:52px;height:52px;margin-left: auto;margin-right: 30px;" src="../assets/images/facebookIcon.png"/>
                            <img style="width:52px;height:52px;margin-right: auto;" src="../assets/images/googleIcon.png"/> -->
                            
                            <!-- <SocialLogin @close="close" style="margin-left:auto;margin-right:auto" /> -->
                        </div>
                        <div class="loginDialogLine"></div>
                        <div class="loginBtnSignUp">New here? <font color="#CEB39A" v-on:click='clickSignUp'>Sign up</font></div>
                        <!-- <OtherLogin></OtherLogin> -->
                    </div>
                </div>
            </div>
        </div>
        
        <a-modal
            class="errorShow"
            title=""
            :visible="visibleError" 
            >
            <div>
                <div class="errorShowDiv">{{ ErrorText }}</div>
                <div class="errorShowBtnDiv">
                    <a-button class="resendBtn" type="primary" :style="{ fontSize: '16px'}"  @click="ResendVerifyClick">
                        Resend verification
                    </a-button>
                    
                    <a-button class="errorOkBtn" type="primary" :style="{ fontSize: '16px'}"  @click="ErrorOkClick">
                        OK
                    </a-button>
                </div>
            </div>
        </a-modal>
        
        <a-modal
            class="errorShow"
            title=""
            :visible="visibleMainError" 
            >
            <div style="padding:10px">
                <div class="errorShowMainText">{{ ErrorMainText }}</div>
                <div style="display:flex;">
                    <a-button class="errorOkBtn2" type="primary" :style="{ fontSize: '16px'}"  @click="ErrorMainOkClick">
                        OK
                    </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';
// import OtherLogin from './OtherLogin';
// import SocialLogin from '@/components/SocialLogin'

// import { VueRecaptcha } from 'vue-recaptcha';

export default {
    data() {
        return {
            disabled: false,
            m_email: '',
            m_password: '',
            loginUser: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        email: 'marcos.henrique@toptal.com',
                        password: 's3cr3tp4sswo4rd'
                    };
                }
            },
            
            isVerify: false,

            visibleError: false,
            ErrorText: '',
            visibleMainError: false,
            ErrorMainText: '',

            visibleLoginSuccess: false,
        };
    },
    components: {
        // OtherLogin
        // SocialLogin,
        // VueRecaptcha
    },
    methods: {
        handleLoginClick: async function() {
            const res = await api.login(this.loginUser);
                    console.log(this.loginUser.email);
            if (res != undefined){
                if (res.error == undefined || res.error == ''){
                    const resStripe = await api.callCustomerApi(this.loginUser.email);
                    var userInfo = {
                        loginType: 'api',
                        api: {
                            auth: 'alyosha',
                            user: {
                                name: res.name,
                                firstname: res.firstname,
                                lastname: res.lastname,
                                agencyname: res.agencyname,
                                email: this.loginUser.email,
                                profileImage: "testurl"
                            },
                            permissionLevel: res.permissionLevel,
                            customerId: resStripe
                        },
                        id: res.userId
                    }
                    this.$store.commit('setLoginUser', userInfo)

                    // this.$message.success('Login success!');
                    // this.visibleLoginSuccess = true;

                    this.$emit('success');
                }
                else{
                    if (res.errorType == "NoVerify"){
                        // this.$error({
                        //     content: res.error,
                        // });
                        this.ErrorText = "Please check your email to verify your email address.";//res.error;
                        this.visibleError = true;
                    }
                    else if(res.errorType == "NoApprove"){
                        // this.$error({
                        //     content: res.error,
                        // });

                        this.ErrorMainText = res.error;
                        this.visibleMainError = true;
                    }
                }
            }
            else{
                // this.$message.error('Please input correct email and password!');
                // this.$error({
                //     content: "Sorry, we couldn't find an account with that username or password. Please try again.",
                // });
                this.ErrorMainText = "Sorry, we couldn't find an account with that username or password. Please try again.";
                this.visibleMainError = true;
            }
        },
        close(){
            this.$emit('close');
        },
        emailChanged(){
            if (this.loginUser.email == undefined || this.loginUser.password == undefined || !this.isVerify)
                this.disabled = true;
            else
                this.disabled = false;
                
            this.loginUser.email = this.loginUser.email.toLowerCase().split(" ").join("");
        },
        closeDialog(){
            window.open("/","_self");// "_blank"
        },
        clickSignUp(){
            this.$emit('openSignup');
        },
        openForgotPassword(){
            this.$emit('openForgot');
        },

        onEvent() {
            this.$refs.recaptcha.execute();
        },
        verifyMethod(){
            this.isVerify = true;
            this.emailChanged();
        },
        expiredMethod(){
            this.isVerify = false;
            this.emailChanged();
        },
        renderMethod(){
            this.isVerify = false;
        },
        errorMethod(){
        },
        ResendVerifyClick: async function() {
            
            this.loginUser['redirecturl'] = window.location.host;
            const res = await api.resendVerify(this.loginUser);
            if (res != null)
                this.$message.success('Resend Verification success!');
                
            this.visibleError = false;
        },
        ErrorOkClick(){
            this.visibleError = false;
        },
        ErrorMainOkClick(){
            this.visibleMainError = false;
        }
    },
    mounted(){
        this.emailChanged()
    },
    created: function () {
    }
}
</script>
<style>

.rc-anchor-checkbox-label {
    font-family: Montserrat;
}</style>