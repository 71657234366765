<template>
    <div class="signup">
        <div>
            <div class="loginDialogDiv">
                <div class="loginDialog">
                    <div class="loginBtnMain">
                        <div style="display:flex">
                            <div class="loginTitle">Sign up</div>
                            <a-icon class="loginCloseIcon" type="close" @click="$emit('close')" :style="{ fontSize: '24px', color:'#AAAAAA' }"/>
                        </div>
                        <div class="signupText3">Get access to great rates and offers to the Collection. </div>
                        <div class="signupText4">Are you a travel agent? <font color="#CEB39A" style="cursor: pointer;" v-on:click='signupAgent'><b>Sign-up here</b></font></div>
                        <div style="display:flex">
                            <a-input class="signupInput1" v-model="signupUser.firstName" placeholder="First Name" :size="'large'" @change="inputChange"/>
                            <a-input class="signupInput2" v-model="signupUser.lastName" placeholder="Last Name" :size="'large'" @change="inputChange"/>
                        </div>
                        <a-auto-complete
                            :data-source="countryList"
                            class="signupAutoComplete"
                            placeholder="Country"
                            v-model="signupUser.country"
                            :size="'large'"
                            @select="onSelectCountry"
                            @blur="onBlurCountry"
                            :filter-option="filterOption"
                        />
                        <a-input class="loginInput" placeholder="Email" v-model="signupUser.email" :size="'large'" @change="emailChanged"/>
                        <div style="position: relative;">
                            <a-input class="loginInput" type="password" placeholder="Password" v-model="signupUser.password" :size="'large'" @change="inputChange"/>
                            <a-popover placement="bottomLeft" class="StarOverDiv" style="position: absolute;bottom:0;left:-20px">
                                <template slot="content">
                                    <div class="StarOverDivCell">
                                        Password guidelines:
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must contain one uppercase and lowercase letter
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must include at least one number
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must be at least 8 characters in length
                                        </div>
                                        <div>
                                            <img class="signupBlackspotImg" src="../assets/images/blackspot.png">
                                            Must not contain the username
                                        </div>
                                    </div>
                                </template>
                                <img style="cursor:pointer" src="../assets/images/passwordInfo.png" alt="" width="16">
                            </a-popover>
                        </div>

                        <a-checkbox class="signupAgreeText" :checked="checked" @change="onChange">
                            <span class="signupAgreeTextMain">
                                I agree to <a class="signupAgreeTextTermsMain" onclick="window.open('/termscondition/', '_blank');">Terms & Conditions</a>, 
                                <a class="signupAgreeTextTermsMain" onclick="window.open('/privacypolicy/', '_blank');">Privacy Policy</a> and subscribe to emails for offers and updates.
                            </span>
                        </a-checkbox>
                        <!-- <div class="loginRecaptchaDiv">
                            <vue-recaptcha class="loginRecaptcha" sitekey="6LcZKt0eAAAAALs9c2BYKyPbgggQAu4WY4PxVCqg" 
                                @verify="verifyMethod" 
                                @expired="expiredMethod"
                                @render="renderMethod"
                                @error="errorMethod">
                            </vue-recaptcha>
                        </div> -->
                        <a-button id="loginBtn" class="loginBtn" :disabled = "disabled" type="primary" :size="'large'" :style="{ fontSize: '17px'}" @click="handleSignupClick">
                            Create account
                        </a-button>
                        <!-- <div class="forgotPassBtn" @click="openForgotPassword">Forgot password?</div> -->
                        <div v-if="false" class="loginText">Or continue with:</div>
                        <!-- <div v-if="false" style="display:flex;margin-top:10px;">
                            <SocialLogin @close="close" style="margin-left:auto;margin-right:auto" />
                        </div> -->
                        <div class="loginDialogLine"></div>
                        <div class="loginBtnSignUp">Already a member? <font color="#CEB39A" v-on:click='clickLogin'>Log in</font></div>
                        <!-- <OtherLogin></OtherLogin> -->
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
// import OtherLogin from './OtherLogin';
// import SocialLogin from '@/components/SocialLogin'
// import { VueRecaptcha } from 'vue-recaptcha';
const countryList= ['US', 'RSS', 'CH'];
export default {
    
    data() {
        return {
            checked: false,
            disabled: true,
            countryList,
            signupUser: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        firstName: '',
                        lastName: '',
                        email:'',
                        password:'',
                        permissionLevel:1,
                        country:'',
                        title:'',
                        phoneNumber:'',
                        agencyName:'',
                        menberName:'',
                        businessID:'',
                        stateProvince:''
                    };
                }
            },
            
            isVerify: false,
            // visibleSignUpSuccess:false

            
        }
    },
    components: {
        // OtherLogin
        // SocialLogin,
        // VueRecaptcha
    },
    methods: {
        close(){
            this.$emit('close');
        },
        
    // eslint-disable-next-line no-unused-vars
        onSelectCountry(value) {
            this.updateButton();
        }, 
        onBlurCountry() {
            if (!this.countryList.includes(this.signupUser.country))
                this.signupUser.country = '';
        }, 
        
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
            );
        },
        onChange(e) {
            this.checked = e.target.checked;
            this.updateButton();
        },
        handleSignupClick: async function() {
            if (this.signupUser.firstName == ""){
                this.$message.error('Please input first name.');
                return
            }
            if (this.signupUser.lastName == ""){
                this.$message.error('Please input last name.');
                return
            }
            // eslint-disable-next-line no-useless-escape
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');//(^\s.+)|(.+\s$)+
            if (!regex.test(this.signupUser.email.toLowerCase())){
                this.$message.error('Please check your email address.');
                return
            }
            if (this.signupUser.country == ""){
                this.$message.error('Please select your country.');
                return
            }
            if (this.signupUser.password == ""){
                this.$message.error('Your merge fields are invalid.');
                return
            }
            if (!this.checkPassword()){
                return
            }
            this.signupUser['redirecturl'] = window.location.host;
            const res = await api.signup(this.signupUser);
            if (res != undefined && res != "" && res.error == undefined){
                // this.$message.success('Welcome to the VUE family. Enjoy our exclusive rates and offers.');
                // this.visibleSignUpSuccess = true;
                this.$emit('success');
            }
            else{
                if (res.error == undefined)
                    this.$message.error('This email is already using by other.');
                else{
                    if (res.error.error_code == "ERROR_CONTACT_EXISTS"){
                        this.$message.error(`${this.signupUser.email} is already a member. Please proceed to sign-in.`);
                    }
                    else{
                        this.$message.error(res.error.error);
                    }
                }
            }
        },
        checkPassword(){
            if (this.signupUser.password.length < 8){
                this.$message.error("Password must be at least 8 characters in length");
                return false;
            }
            ////  (?=.*?[#?!@$%^&*-])
            // eslint-disable-next-line no-useless-escape
            let regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
            if (!regex.test(this.signupUser.password)){
                this.$message.error('Password must include at least one number, one uppercase letter, and one lowercase letter');
                return false;
            }
            if (this.signupUser.password.includes(this.signupUser.firstName) || this.signupUser.password.includes(this.signupUser.lastName)){
                this.$message.error("Password must not contain the username");
                return false;
            }
            return true;
            // -Is case sensitive
            // -Can contain letters (a-z), numbers, and special characters
            // -Must be at least 8 characters in length
            // -Must include at least one number, one uppercase letter, and one lowercase letter
            // -Must not contain the username
        },
        
        emailChanged(){
            this.signupUser.email = this.signupUser.email.toLowerCase().split(" ").join("");
            this.updateButton();
        },
        inputChange(){
            this.updateButton();
        },
        updateButton(){
            if(!this.checked){
                this.disabled = true;
            }
            else
                this.disabled = false;
            // if (this.signupUser.firstName == undefined || this.signupUser.lastName == undefined ||
            //     this.signupUser.country == undefined || this.signupUser.email == undefined || 
            //     this.signupUser.password == undefined || 
            //     this.signupUser.firstName == '' || this.signupUser.lastName == '' ||
            //     this.signupUser.country == '' || this.signupUser.email == '' || 
            //     this.signupUser.password == '' || 
            //     !this.checked || !this.isVerify)
            //     this.disabled = true;
            // else
            //     this.disabled = false;
        },
        clickLogin(){
            this.$emit('openLogin');
        },
        signupAgent(){
            
            this.$emit('openSignupAgent');
        },
        
        openForgotPassword(){
            this.$emit('openForgot');
        },

        
        onEvent() {
            this.$refs.recaptcha.execute();
        },
        verifyMethod(){
            this.isVerify = true;
            this.updateButton();
        },
        expiredMethod(){
            this.isVerify = false;
            this.updateButton();
        },
        renderMethod(){
            this.isVerify = false;
        },
        errorMethod(){

        }
    },
    created(){
        
        this.signupUser = {
            firstName: '',
            lastName: '',
            email:'',
            password:'',
            permissionLevel:1,
            country:'',
            title:'',
            phoneNumber:'',
            agencyName:'',
            menberName:'',
            businessID:'',
            stateProvince:''
        };
        this.countryList = common.getCountryList();
    },
    mounted(){
        this.updateButton();
    }
}
</script>